import { Routes } from '@angular/router';
import { AuthGuard, RoleGuard } from './guards';
import { AccountTypeEnum, PagesEnum } from './models';

export const routes: Routes = [
  {
    path: '',
    redirectTo: `/${PagesEnum.LOGIN}`,
    pathMatch: 'full',
    data: { titleTranslationKey: 'dashboard' },
  },
  {
    path: PagesEnum.DASHBOARD,
    loadChildren: () => import('./views/dashboard/dashboard.routing').then(mod => mod.DASHBOARD_ROUTES),
    data: {
      titleTranslationKey: 'dashboard',
      preload: true,
      roles: [AccountTypeEnum.ADMIN, AccountTypeEnum.MANAGER, AccountTypeEnum.CHEF, AccountTypeEnum.VENUE, AccountTypeEnum.WAITER],
    },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: PagesEnum.LOGIN,
    loadComponent: () => import('./views/login/login.component').then(mod => mod.LoginComponent),
    data: { titleTranslationKey: 'login', hideSidebar: true, preload: true },
  },
  {
    path: PagesEnum.SWITCH_USER,
    loadComponent: () => import('./views/switch-user/switch-user.component').then(mod => mod.SwitchUserComponent),
    data: {
      titleTranslationKey: 'switch-user',
      hideSidebar: true,
      preload: true,
      roles: [AccountTypeEnum.ADMIN, AccountTypeEnum.MANAGER, AccountTypeEnum.CHEF, AccountTypeEnum.VENUE, AccountTypeEnum.WAITER],
    },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: PagesEnum.REGISTER,
    loadComponent: () => import('./views/register/register.component').then(mod => mod.RegisterComponent),
    data: { titleTranslationKey: 'sign-up', hideSidebar: true, preload: true },
  },
  {
    path: PagesEnum.ANALYTICS,
    loadComponent: () => import('./views/analytics/analytics.component').then(mod => mod.AnalyticsComponent),
    data: {
      titleTranslationKey: 'analytics',
      roles: [AccountTypeEnum.ADMIN, AccountTypeEnum.MANAGER],
    },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: PagesEnum.PRODUCTS,
    loadChildren: () => import('./views/products/products.routing').then(mod => mod.PRODUCTS_ROUTES),
    data: {
      titleTranslationKey: 'products',
      roles: [AccountTypeEnum.ADMIN, AccountTypeEnum.MANAGER, AccountTypeEnum.CHEF, AccountTypeEnum.VENUE, AccountTypeEnum.WAITER],
    },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: PagesEnum.NOT_FOUND,
    loadComponent: () => import('./views/not-found/not-found.component').then(mod => mod.NotFoundComponent),
    data: { titleTranslationKey: 'page-not-found' },
    canActivate: [AuthGuard],
  },
  {
    path: PagesEnum.NOTIFICATIONS,
    loadComponent: () => import('./views/notifications/notifications.component').then(mod => mod.NotificationsComponent),
    data: {
      titleTranslationKey: 'notifications',
      roles: [AccountTypeEnum.ADMIN, AccountTypeEnum.MANAGER, AccountTypeEnum.CHEF, AccountTypeEnum.VENUE, AccountTypeEnum.WAITER],
    },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: PagesEnum.CASH_REGISTER,
    loadComponent: () => import('./views/cash-register/cash-register.component').then(mod => mod.CashRegisterComponent),
    data: {
      titleTranslationKey: 'cash-register',
      roles: [AccountTypeEnum.ADMIN, AccountTypeEnum.MANAGER, AccountTypeEnum.CHEF, AccountTypeEnum.VENUE, AccountTypeEnum.WAITER],
    },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: PagesEnum.DIRECT_SALE,
    loadComponent: () => import('./views/direct-sale/direct-sale.component').then(mod => mod.DirectSaleComponent),
    data: {
      titleTranslationKey: 'direct-sale',
      roles: [AccountTypeEnum.ADMIN, AccountTypeEnum.MANAGER, AccountTypeEnum.VENUE, AccountTypeEnum.WAITER],
    },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: PagesEnum.ORDERS,
    loadComponent: () => import('./views/orders/orders.component').then(mod => mod.OrdersComponent),
    data: {
      titleTranslationKey: 'orders',
      roles: [AccountTypeEnum.ADMIN, AccountTypeEnum.MANAGER, AccountTypeEnum.CHEF, AccountTypeEnum.VENUE, AccountTypeEnum.WAITER],
    },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: PagesEnum.SUBORDERS,
    loadComponent: () => import('./views/suborders/suborders.component').then(mod => mod.SubordersComponent),
    data: {
      titleTranslationKey: 'suborders',
      roles: [AccountTypeEnum.ADMIN, AccountTypeEnum.MANAGER, AccountTypeEnum.CHEF, AccountTypeEnum.VENUE, AccountTypeEnum.WAITER],
    },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: PagesEnum.PAYMENT,
    loadChildren: () => import('./views/payment/payment.routing').then(mod => mod.PAYMENT_ROUTES),
    data: {
      titleTranslationKey: 'payment',
      roles: [AccountTypeEnum.ADMIN, AccountTypeEnum.MANAGER, AccountTypeEnum.CHEF, AccountTypeEnum.VENUE, AccountTypeEnum.WAITER],
    },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: PagesEnum.PROFILE,
    loadComponent: () => import('./views/settings/profile/profile.component').then(mod => mod.ProfileComponent),
    data: {
      titleTranslationKey: 'profile',
      roles: [AccountTypeEnum.ADMIN, AccountTypeEnum.MANAGER, AccountTypeEnum.CHEF, AccountTypeEnum.VENUE, AccountTypeEnum.WAITER],
    },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: PagesEnum.ASSISTANCE,
    loadComponent: () => import('./views/assistance/assistance.component').then(mod => mod.AssistanceComponent),
    data: {
      titleTranslationKey: 'assistance',
      roles: [AccountTypeEnum.ADMIN, AccountTypeEnum.MANAGER, AccountTypeEnum.CHEF, AccountTypeEnum.VENUE, AccountTypeEnum.WAITER],
    },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: PagesEnum.VENUE,
    loadComponent: () => import('./views/settings/venue/venue.component').then(mod => mod.VenueComponent),
    data: {
      titleTranslationKey: 'venue',
      roles: [AccountTypeEnum.ADMIN, AccountTypeEnum.MANAGER],
    },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: PagesEnum.SETTINGS,
    loadComponent: () => import('./views/settings/settings.component').then(mod => mod.SettingsComponent),
    data: {
      titleTranslationKey: 'settings',
      roles: [AccountTypeEnum.ADMIN, AccountTypeEnum.MANAGER, AccountTypeEnum.CHEF, AccountTypeEnum.VENUE, AccountTypeEnum.WAITER],
    },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: PagesEnum.SUPPORT,
    loadComponent: () => import('./views/support/support.component').then(mod => mod.SupportComponent),
    data: {
      titleTranslationKey: 'support',
      roles: [AccountTypeEnum.ADMIN, AccountTypeEnum.MANAGER, AccountTypeEnum.CHEF, AccountTypeEnum.VENUE, AccountTypeEnum.WAITER],
    },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: PagesEnum.TABLES,
    loadChildren: () => import('./views/tables/table.routing').then(mod => mod.TABLE_ROUTES),
    data: {
      titleTranslationKey: 'tables',
      roles: [AccountTypeEnum.ADMIN, AccountTypeEnum.MANAGER, AccountTypeEnum.CHEF, AccountTypeEnum.VENUE, AccountTypeEnum.WAITER],
    },
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: '**',
    redirectTo: PagesEnum.NOT_FOUND,
    data: { titleTranslationKey: 'page-not-found' },
    pathMatch: 'full',
  },
];
