import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { NgOptimizedImage, isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  HostBinding,
  Inject,
  OnInit,
  PLATFORM_ID,
  WritableSignal,
  afterNextRender,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { Router, RouterOutlet, RoutesRecognized } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { distinctUntilChanged } from 'rxjs';
import { addAnimation, fadeAnimation, removeAnimation, routerAnimation, slideRightAnimation } from './animations';
import { AppMainComponent } from './components/app-main/app-main.component';
import { AppSideMenuComponent } from './components/app-side-menu/app-side-menu.component';
import { LOCALE_KEY, POS, ROMANIAN, WAYTR } from './constants';
import { stopPropagation } from './helpers';
import { LocalStorageService, TitleService, UserAccountService } from './services';
import { LocalesService } from './services/locales.service';
import {
  WaytrIconsService,
  waytrAdmin,
  waytrAnalytics,
  waytrArrowDown,
  waytrArrowLeft,
  waytrArrowRight,
  waytrBowl,
  waytrBreakfast,
  waytrBurger,
  waytrBusy,
  waytrCard,
  waytrCart,
  waytrChecklist,
  waytrChicken,
  waytrClean,
  waytrCutlery,
  waytrDirectSale,
  waytrDiscount,
  waytrDrink,
  waytrEdit,
  waytrFoodMenu,
  waytrFresh,
  waytrHash,
  waytrLink,
  waytrLink2,
  waytrMushrooms,
  waytrNoodle,
  waytrNotification,
  waytrOrders,
  waytrPastry,
  waytrPizza,
  waytrPromoted,
  waytrSales,
  waytrSaltAndPepper,
  waytrSoup,
  waytrSplit,
  waytrStar,
  waytrSushi,
  waytrSweet,
  waytrSwitch,
  waytrTable,
  waytrTableManagement,
  waytrTaco,
  waytrTissue,
  waytrUpload,
  waytrWaiter,
  waytrWallet,
  waytrWeight,
} from './shared/components';
import { LoginComponent } from './views/login/login.component';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routerAnimation, addAnimation, removeAnimation, slideRightAnimation, fadeAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterOutlet,
    FormsModule,
    AppSideMenuComponent,
    AppMainComponent,
    NzIconModule,
    NgOptimizedImage,
    TranslateModule,
    LoginComponent,
  ],
})
export class AppComponent implements OnInit {
  readonly #userAccountService = inject(UserAccountService);
  readonly #waytrIconsService = inject(WaytrIconsService);
  readonly #titleService = inject(TitleService);
  readonly #router = inject(Router);
  readonly #destroyRef = inject(DestroyRef);
  readonly #translateService = inject(TranslateService);
  readonly #localesService = inject(LocalesService);
  readonly #localStorageService = inject(LocalStorageService);

  protected Breakpoints = Breakpoints;
  protected stopPropagation = stopPropagation;

  protected sidebarCollapsedClicked: WritableSignal<boolean> = signal<boolean>(false);
  protected hideSidebar: WritableSignal<boolean> = signal<boolean>(false);
  protected mediumBreakpoint: WritableSignal<boolean> = signal<boolean>(false);
  protected smallBreakpoint: WritableSignal<boolean> = signal<boolean>(false);
  protected xsBreakpoint: WritableSignal<boolean> = signal<boolean>(false);

  @HostBinding('class.app-not-loaded') get isAppNotLoaded() {
    return !this.#userAccountService.userDetails();
  }

  @HostBinding('class.hide-sidebar') get isSidebarHidden() {
    return this.hideSidebar();
  }

  @HostBinding('class.sidebar-collapsed') get isSidebarCollapsed() {
    return this.sidebarCollapsedClicked();
  }

  @HostBinding('class.medium') get isMediumBreakpoint() {
    return this.mediumBreakpoint();
  }

  @HostBinding('class.small') get isSmallBreakpoint() {
    return this.smallBreakpoint();
  }

  @HostBinding('class.xs') get isXsBreakpoint() {
    return this.xsBreakpoint();
  }

  readonly #breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Medium, Breakpoints.Small, Breakpoints.XSmall])
    .pipe(distinctUntilChanged());

  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    @Inject(PLATFORM_ID) private readonly platformId: string,
  ) {
    if (isPlatformBrowser(platformId)) {
      this.#translateService.setDefaultLang(ROMANIAN);
      this.setLanguage();
    }

    this.#router.events.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(event => {
      if (event instanceof RoutesRecognized) {
        const data = event.state.root.firstChild?.data;
        const titleTranslationKey: string = data?.['titleTranslationKey'];
        const hideSidebar: boolean = data?.['hideSidebar'];

        this.#titleService.changeToNewPage(this.#translateService.instant(titleTranslationKey));
        this.hideSidebar.set(hideSidebar);
      }
    });

    this.#waytrIconsService.addIcons([
      waytrFresh,
      waytrBowl,
      waytrCart,
      waytrChicken,
      waytrCutlery,
      waytrMushrooms,
      waytrPastry,
      waytrPizza,
      waytrSweet,
      waytrOrders,
      waytrFoodMenu,
      waytrCart,
      waytrTableManagement,
      waytrWaiter,
      waytrDirectSale,
      waytrTable,
      waytrAnalytics,
      waytrNotification,
      waytrBusy,
      waytrStar,
      waytrSales,
      waytrHash,
      waytrLink,
      waytrCard,
      waytrUpload,
      waytrEdit,
      waytrLink2,
      waytrTaco,
      waytrNoodle,
      waytrBreakfast,
      waytrSoup,
      waytrSushi,
      waytrBurger,
      waytrDrink,
      waytrSaltAndPepper,
      waytrClean,
      waytrTissue,
      waytrArrowLeft,
      waytrArrowRight,
      waytrDiscount,
      waytrSplit,
      waytrChecklist,
      waytrArrowDown,
      waytrSwitch,
      waytrWallet,
      waytrWeight,
      waytrPromoted,
      waytrAdmin,
    ]);

    afterNextRender(() => {
      this.#localesService.getAllLocales().then(locales => this.#localesService.setAllLocales(locales));
      this.#breakpoint$.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(() => this.breakpointChanged());
    });
  }

  ngOnInit() {
    this.#titleService.loadInitialTitle(`${WAYTR} ${POS}`);

    // const updatesAvailable = this.swUpdate.versionUpdates.pipe(
    //   filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
    //   map(evt => ({
    //     type: 'UPDATE_AVAILABLE',
    //     current: evt.currentVersion,
    //     available: evt.latestVersion,
    //   })),
    // );
    //
    // if (updatesAvailable) {
    //   console.log('New version available');
    //   if (
    //     window.confirm(
    //       'New version available. Do you want to update the application?',
    //     )
    //   ) {
    //     window.location.reload();
    //   }
    // }
  }

  private breakpointChanged() {
    if (this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.xsBreakpoint.set(true);
      this.smallBreakpoint.set(false);
      this.mediumBreakpoint.set(false);
    } else if (this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.smallBreakpoint.set(true);
      this.xsBreakpoint.set(false);
      this.mediumBreakpoint.set(false);
    } else if (this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.mediumBreakpoint.set(true);
      this.xsBreakpoint.set(false);
      this.smallBreakpoint.set(false);
    } else {
      this.xsBreakpoint.set(false);
      this.smallBreakpoint.set(false);
      this.mediumBreakpoint.set(false);
    }
  }

  private setLanguage() {
    const locale: string = this.#localStorageService.getData(LOCALE_KEY);
    if (locale) {
      this.#localesService.setLocale(locale);
    }
  }
}
